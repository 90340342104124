<template>
  <footer
    class="text-white site-footer bg-bc-dark-blue isolate"
    aria-labelledby="footer-heading"
  >
    <img src="/img/mountains.svg" class="w-full bg-white" />
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
      <div class="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 xl:gap-8">
        <div
          class="grid grid-cols-1 sm:grid-cols-3 gap-8 col-span-1 sm:col-span-3"
        >
          <div class="col-span-1 sm:col-span-1">
            <div>
              <h3 class="font-semibold border-b theme-text-2xl">
                Home Collections
              </h3>
              <ul role="list" class="mt-4 grid grid-cols-1 gap-4">
                <li v-for="item in navigation.collections" :key="item.name">
                  <NuxtLink
                    :href="item.href"
                    class="hover:underline leading-none text-xl text-white !font-semibold !font-archer theme-text-body"
                  >
                    {{ item.name }}
                  </NuxtLink>
                  <span
                    v-if="item.description"
                    class="block !text-sm font-normal text-white theme-text-sm"
                  >
                    {{ item.description }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-span-1 sm:col-span-2">
            <div>
              <h3 class="font-semibold border-b theme-text-2xl">
                Neighborhoods
              </h3>
              <ul
                role="list"
                class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4"
              >
                <li v-for="item in navigation.neighborhoods" :key="item.id" :class="item.cssClasses.join(' ')">
                  <NuxtLink
                    :href="item.href"
                    class="hover:underline text-xl leading-none text-white !font-semibold !font-archer theme-text-body"
                  >
                    {{ item.name.title
                    }}<span v-if="item.name.city">
                      in {{ item.name.city }}</span
                    >
                  </NuxtLink>
                  <br />
                  <span class="text-white theme-text-base !text-sm">
                    {{ item.subtitle || item.description }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="relative xl:-top-4 p-4 mt-8 overflow-hidden xl:mt-0 col-span-2 lg:col-span-1 bg-bc-gray rounded-2xl h-[400px]"
        >
          <div
            class="w-full overflow-hidden h-[140px] absolute top-0 inset-x-0 bg-gradient-to-b to-[#CCB59F] from-[#E1DBD4]"
          >
            <div
              class="bg-gradient-to-r from-[#A49280]/80 to-[#E1DBD4]/5 absolute top-0 right-0 bottom-3 left-0"
            ></div>
            <img
              src="/img/Alissa_Seth_OSC--cutout.png"
              class="w-auto h-[160px] absolute -top-2 -right-4"
              alt=""
            />
            <div class="absolute inset-5">
              <h3
                class="!font-semibold !text-3xl text-white drop-shadow-text theme-text-2xl mb-2"
              >
                Questions?
              </h3>
              <h4 class="ml-2 drop-shadow-text theme-text-sm">
                We're here to help!
              </h4>
            </div>
          </div>
          <div
            class="w-full h-[88px] bg-white absolute bottom-0 inset-x-0"
          ></div>
          <div
            class="absolute border border-2 rounded-2xl border-white/50 inset-1"
          >
            <div
              class="z-10 mt-[170px] w-full flex justify-center flex-col gap-4 w-5/6 mx-auto"
            >
              <NuxtLink
                to="/contact-us/ask-a-question/"
                class="inline-flex items-center justify-center w-full px-3 py-2 mx-auto text-sm font-medium text-white border border-transparent theme-text-lg bg-bc-blue leading-4 rounded-md shadow-sm hover:bg-bc-blue/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bc-blue"
              >
                Ask a Question Now
              </NuxtLink>
              <NuxtLink
                to="/contact-us/our-locations/"
                class="inline-flex items-center justify-center w-full px-3 py-2 mx-auto text-sm font-medium bg-white border border-gray-300 theme-text-lg text-bc-blue shadow-sm leading-4 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bc-blue"
              >
                Visit Us
              </NuxtLink>
            </div>
            <div class="flex justify-center w-full absolute bottom-[69px]">
              <NuxtLink
                to="tel:720-636-7088"
                target="_new"
                class="inline-flex items-center border border-transparent text-sm font-medium !font-archer !leading-4 py-1 px-6 rounded-full shadow-sm text-white bg-[#858D9B] hover:bg-[#858D9B]/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bc-blue"
              >
                CALL US
              </NuxtLink>
            </div>
            <div class="flex justify-center w-full absolute bottom-[30px]">
              <NuxtLink
                to="tel:720-636-7088"
                target="_new"
                class="flex gap-1 text-[#6B7280] theme-text-lg"
                ><img src="/img/phone-icon.svg" class="inline-block" />(720)
                636-7088</NuxtLink
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t">
        <div
          class="relative px-3 mx-auto bg-bc-dark-blue space-x-3 -top-4 w-fit"
        >
          <NuxtLink
            v-for="item in navigation.social"
            :key="item.name"
            :href="item.href"
            target="_new"
            class=""
          >
            <span class="sr-only">{{ item.name }}</span>
            <component
              :is="item.icon"
              class="inline-block w-8 h-8 opacity-70"
              aria-hidden="true"
            />
          </NuxtLink>
        </div>
      </div>
      <div
        class="flex flex-wrap items-end mt-4 gap-5 opacity-80 xl:flex-nowrap"
      >
        <p class="text-xs text-white font-inter">
          Copyright 2024 Boulder Creek Brands LLC. Boulder Creek Neighborhoods®
          is a licensed trademark and tradename of Boulder Creek Brands LLC, a
          Colorado limited liability company. Floor plan information is for
          illustration purposes only and is not part of a legal contract. All
          pricing, availability, and specifications are subject to change
          without notice.
          <NuxtLink href="/privacyterms/" class="underline"
            >Privacy / Terms</NuxtLink
          >
        </p>
        <img
          srcset="/img/HBA_Logo_2x.png 2x, /img/HBA_Logo_1x.png 1x"
          src="/img/HBA_Logo_2x.png"
          class="w-[119px] h-[34px]"
        />
        <img
          srcset="
            /img/Best_Places_to_Work_Logo_2x.png 2x,
            /img/Best_Places_to_Work_Logo_1x.png 1x
          "
          src="/img/Best_Places_to_Work_Logo_2x.png"
          alt=""
          class="w-[176px] h-[38px]"
        />
        <img
          srcset="
            /img/Energy_Star_Logo_2x.png 2x,
            /img/Energy_Star_Logo_1x.png 1x
          "
          src="/img/Energy_Star_Logo_2x.png"
          alt=""
          class="w-[48px] h-[30px]"
        />
        <img
          srcset="
            /img/Equal_Housing_Opportunity_Logo_2x.png 2x,
            /img/Equal_Housing_Opportunity_Logo_1x.png 1x
          "
          src="/img/Equal_Housing_Opportunity_Logo_2x.png"
          alt=""
          class="w-[36px] h-[33px]"
        />
      </div>
    </div>
  </footer>
</template>
<style>
  .featured-footer {
    @apply relative border rounded border-[#fade96] sm:py-4 py-3.5 sm:px-6 px-3.5 sm:-ml-6 -ml-0 sm:mt-0 mt-4;

    &::before {
      content:'';
      background-image: url("/img/yellow-star.svg");
      background-repeat: no-repeat;
      background-color: #387592;
      background-position-x: center;
      height: 13px;
      width: 14px;
      display:block;
      position:absolute;
      top:-7px;
      left:20px;
      padding: 0 12px;

      @media screen and (max-width:639px) {
        left:8px;
      }
    }

    &::after {
      content:'FEATURED NEIGHBORHOOD';
      position:absolute;
      top:-7px;
      left:40px;
      color: #FADE96;
      background-color: #387592;
      font-family: Arial;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.2px;
      padding: 0 6px;

      @media screen and (max-width:639px) {
        left:28px;
      }
    }
  }
</style>
<script setup>
import { defineComponent, h } from "vue";

const props = defineProps({
  collections: Array,
  neighborhoods: Array,
});

const navigation = {
  collections: [],
  neighborhoods: [],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/bouldercreekneighborhoods/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/livebouldercreek/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/boulder-creek---life-and-home",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 448 512" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
  ],
};

watchEffect(() => {
  props.collections.forEach((item) =>
    navigation.collections.push({
      name: item.label,
      description: item.description,
      href: item.path,
    })
  );

  props.neighborhoods.forEach((item) =>
    navigation.neighborhoods.push({
      name: {
        title: item.label,
        city: item.connectedNode?.node?.neighborhood?.city,
      },
      href: item.connectedNode?.node?.uri,
      subtitle: getNeighborhoodNavSubtitle(
        item.connectedNode?.node?.types,
        getRoundedPrice(item.connectedNode?.node?.basePrice),
        item.connectedNode?.node?.neighborhood?.status,
        true
      ),
      description: item.description,
      cssClasses: item.cssClasses,
    })
  );
});
</script>
