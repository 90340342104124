<template>
  <div
    class="py-4 text-sm text-gray-500 breadcrumb-nav theme-text-base font-arial"
    v-if="breadcrumbs.breadcrumbsList?.length"
  >
    <div class="px-6 mx-auto xl:px-0 max-w-screen-xl">
      <ul class="flex flex-wrap">
        <li class="ml-3">
          <NuxtLink to="/">
            <HomeIcon class="w-5 h-5" alt="Home" />
          </NuxtLink>
        </li>
        <li
          v-for="crumb in breadcrumbs.breadcrumbsList"
          class="before:content-['/']"
        >
          <a
            v-if="crumb.refreshPage"
            :href="crumb.link"
            :key="crumb.link"
            class="whitespace-nowrap"
          >
            {{ crumb.title }}
          </a>
          <NuxtLink
            v-else
            :href="crumb.link"
            :key="crumb.link"
            class="whitespace-nowrap"
          >
            {{ crumb.title }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
li {
  &::before {
    @apply mx-3;
  }
}
</style>

<script setup>
import { HomeIcon } from "@heroicons/vue/solid/index.js";
const breadcrumbs = useBreadcrumbs();
const route = useRoute();
watch(
  () => route.query,
  () => refreshNuxtData("route")
);
</script>
